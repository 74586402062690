.flexGrow{
flex-grow: 1;
}

.flex{
display: flex;
}

.buttonHint  {
margin-top: 5px;
margin : 5px 4px 0px 4px;
}

.errorBorderContainer{
  border: 1px solid #F44336;
  padding: 10px 15px 10px 15px;
  border-radius: 4px;
  margin:  7px 0px 4px 0px;
}

.errorColor{
  color:  #F44336!important;
}

.noCollapse {
  padding: 0.05px;
}

.borderContainer{
    border: 1px solid rgba(0,0,0,0.25);
    padding: 10px 15px 10px 15px;
    border-radius: 4px;
    margin:  7px 0px 4px 0px;
  }

  .marginContainer{
    margin:  7px 0px 4px 0px;
  }

  .warningColor {
    color: #ce8128;
  }

  .shadowContainer {
    margin : 6px;
    padding: 10px;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.05);
  }

  .toolbarRTE {
    padding:  0px !important;
    border: none;
  }


  .editorRTEReadOnly {
    background-color:#EEEEEE!important;
  }

  .editorRTEError {
    border: 1px solid #F44336;
  }

 /* .ql-container {
    min-height: 10em;
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    background: #fefcfc;
  }

  .ql-toolbar {
    background: #eaecec;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
    border-bottom: none;
  } */

